@import '@siemens/ix-icons/dist/css/ix-icons.css';
@import '@siemens/ix/dist/siemens-ix/siemens-ix.css';
@import '@siemens/ix-brand-theme/dist/ix-brand-theme/ix-brand-theme.css';

/* Order matters with the imports */
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';
@import '@siemens/ix-aggrid/dist/ix-aggrid/ix-aggrid.css';

/* If you want to use a Material Icon within Siemens iX components, map them as follows: */
.glyph-mat-category:before { content: "\E574"; font-family: "Material Icons"; }
.glyph-mat-train:before { content: "\E570"; font-family: "Material Icons"; }
.glyph-mat-menu-book:before { content: "\EA19"; font-family: "Material Icons"; }
.glyph-mat-phone-forwarded:before { content: "\E61C"; font-family: "Material Icons"; }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

/* Typography stuff */
h1, h2 {
    text-align: center;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.disabled-groups {
    opacity: 0.5;
    pointer-events: none;
  }

.md-drppicker{
    background-color: var(--theme-color-component-8) !important;
}

.ranges > ul > li > button{
    color: var(--theme-color-soft-text);
}

.md-drppicker > .ranges > ul > li:hover{
    background-color: var(--theme-color-ghost--selected-hover) !important;
    color: var(--theme-color-inv-contrast-text) !important;
}

.calendar-table{
    background-color: var(--theme-color-component-8) !important;
    border: 1px solid var(--theme-color-x-weak-bdr) !important;
}

.available, .month, .week-days > th{
    color: var(--theme-color-component-10) !important;
}

.md-drppicker > .available:hover{
    background-color: var(--theme-color-ghost--selected-hover) !important;
    color: var(--theme-color-inv-contrast-text) !important;
}

.off{
    color: var(--theme-color-4) !important;
    background-color: var(--theme-color-component-8) !important;
}

.active{
    background-color: var(--theme-chart-1) !important;
    color: var(--theme-color-inv-contrast-text) !important;
}

.off.active, .active.off {
    color: var(--theme-color-4) !important;
    background-color: var(--theme-color-component-8) !important;
}

.in-range {
    background-color: var(--theme-chart-1) !important;
    color: var(--theme-color-inv-contrast-text) !important;
}

.prev, .next {
    background-color: var(--theme-color-component-2) !important;
}

.btn, .clear {
    background-color: var(--theme-color-primary) !important;
    color: var(--theme-color-inv-contrast-text) !important;
}

.clear {
    margin-right: 0.5rem !important;
    background-color: var(--theme-color-primary) !important;
    color: var(--theme-color-inv-contrast-text) !important;
}

.clear > svg{
    display: none !important;
}